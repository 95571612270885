import React from 'react';
//import ReactDOM from 'react-dom';
import App from './components/App';
import GlobalStyle from './style/global';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import i18n from './assets/i18n/i18n';
import { I18nextProvider } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
  <Wrapper>
    <GlobalStyle />
    <App />
  </Wrapper>,
  </I18nextProvider>,
  document.getElementById('root'),
);
