import axios from 'axios';
import { Observable } from 'rxjs';

class ClientAuthorization {
  //apiBaseUrl = 'https://mgc-dev.doole.io' 

  post(endpt, items, server_url): Observable<any> {
    const endpoint = `${server_url}/${endpt}`;

    return new Observable(observer => {
      axios.post(endpoint, items)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }
}

export default ClientAuthorization;
