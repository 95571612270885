import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import esTranslation from '../../locales/es.json';
import caTranslation from '../../locales/ca.json';
import enTranslation from '../../locales/en.json';


i18n
  .use(initReactI18next)
  .init({
    resources: {
    en: {
      translation: enTranslation,
      },
    ca: {
      translation: caTranslation,
      },
      es: {
        translation: esTranslation,
      },
  },
  lng: 'ca', // idioma predeterminado
  fallbackLng: 'es', // idioma de respaldo
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;