import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTimer } from '../hooks';
import media from '../style/media';
import { useMediaPredicate } from 'react-media-hook';
import ProgressBar from './progressBar';
import { useTranslation } from 'react-i18next';
import flecha from '../assets/fletxa_anim.gif';

const Value = styled.div`
  font-size: 16px;
  color: #01061b;
  ${media.tablet`
    color: #0653f4;
    font-size: 24px;
    width: '100%',
 `}
`;

const Timer = ({ started, durationSeconds, isError, isFinish}) => {
  let seconds = useTimer(started, durationSeconds);
  const [showPopup1, setShowPopup] = useState(false);
  const { t } = useTranslation();

 
  if (durationSeconds <= seconds && !isError &&  !showPopup1  ) {
    console.log("errorrr", isError)
    console.log("isFinish", isFinish)
   
      setShowPopup(true);
  }
  return (
    <div  style={{ width: '100%',}}>
      {/* Barra de progreso siempre visible */}
      <Value>
      {t('translation.analizando')}          
        </Value>
      <ProgressBar maxTime={durationSeconds} currentTime={seconds} />

      {/* Contenido adicional, como la duración y el popup */}
      <div>
       
 
        {showPopup1 && (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgb(248 238 238 / 70%)', 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
           
            <div style={{ border: 'solid',  backgroundColor: 'white', padding: '20px', borderRadius: '5px', margin: '20px', fontSize: 'larger'}}>
              <p>{t('translation.send_data')}</p>
            </div>
            
          </div>
            )}
       
      </div>
    </div>
  );
};


export default Timer;
