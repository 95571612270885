import { createLocalStorageStateHook } from 'use-local-storage-state';

export const DEFAULT_MEASUREMENT_DURATION = 50;
export const MIN_MEASUREMENT_DURATION = 20;
export const MAX_MEASUREMENT_DURATION = 180;

export const useLicenseKey = createLocalStorageStateHook('licenseKey', '1CC8C7-A92E74-46A7A0-19E110-E73EF0-303AB0');
//export const useLicenseKey = createLocalStorageStateHook('licenseKey', 'DF4F6E-67E49B-40E68E-C7B6C9-EF9BE8-34D704');  Pruebas
export const useProductId = createLocalStorageStateHook('productId', null);
export const useMeasurementDuration = createLocalStorageStateHook(
  'measurementDuration',
  DEFAULT_MEASUREMENT_DURATION,
);
