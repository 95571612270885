import React, { useState, useEffect } from 'react';

const ProgressBar = ({ maxTime, currentTime }) => {
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      if (currentTime <= maxTime) {
        const newProgress = (currentTime / maxTime) * 100;
        setProgress(newProgress);
      }
    }, [currentTime, maxTime]);
  
    return (
      <div style={{ width: '100%', height: '6px', backgroundColor: '#eee', borderRadius: '4px', overflow: 'hidden' }}>
     <div
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: '#0091b3',
          }}
        />
      </div>
    );
  };
  
  export default ProgressBar;

  