import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  isMobile,
  isTablet,
  isIos,
  HealthMonitorCodes,
  SessionState,
} from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import {
  useError,
  useLicenseKey,
  useMeasurementDuration,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useWarning,
} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import InfoBar from './InfoBar';
import { ErrorAlert, InfoAlert, WarningAlert } from './alert';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import TopBar from './TopBar';
import Mask from '../assets/mask.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../assets/i18n/i18n';
import flecha from '../assets/fletxa_anim.gif';
import { delay } from 'rxjs';



type IsError = true | false;

const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  z-index: ${({ isSettingsOpen }) => isSettingsOpen && '-1'};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`
  width: auto;
  height: ${({ isMobile }) => isMobile && '100%'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    margin: 40px 0 60px 0;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => isMobile && '100%'};
  ${media.tablet`
    width: 812px;
    height: 609px;
  `} ${media.wide`
    width: 1016px;
    height: 762px;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? 'contain' : 'cover')};
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${() => (isIos() ? 'unset' : isMobile() ? 'cover' : 'contain')};
  ${mirror}
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

const BinahMonitor = ({  
  showMonitor,
  cameraId,
  onLicenseStatus,
  onSettingsClick,
  isSettingsOpen,
  location,
}) => {
  if (!showMonitor) {
    return null;
  }
  
  const { t } = useTranslation();
  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(
    false,
  );
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
  } = useMonitor(
    video,
    cameraId,
    processingTime,
    licenseKey,
    null,
    startMeasuring,
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    [],
  );
 // const [showPopup, setShowPopup] = useState(!isLoading);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupEnd, setShowPopupEnd] = useState(false);
  const [warningCode, setWarningCode] = useState<number | null>(null);
  const [isError, setIsError ] = useState(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [showError, setShowError] = useState(false);
  //let isError: IsError = false;isFinish
  const [isFinish, setIsFinish ] = useState(false);

  const [reiniciar, setReiniciar] = useState(false);

  // Estado para controlar el reinicio del temporizador
  const [resetTimer, setResetTimer] = useState(false);


  const togglePopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Evitar que se propague el evento si es necesario
    setShowPopup(!showPopup);
    setIsError(false); 
    handleButtonClick();
    setReiniciar(true);
    setIsFinish(false);
    
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };



  const handleButtonClick = useCallback(() => {
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000),
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }  
   
    if (info?.message) {
      console.log("mensahe warning", info?.message)     
    }
  }, [sessionState, setIsLoading, processingTime]);

  const handleLanguageChange = (language) => {
    changeLanguage(language);
  };

  

  useEffect(() => {    
    
   // if(info?.message ===)
  
    // Verificar si el mensaje de advertencia tiene el código 3005
    if (error?.code === 3003) {
      // Mostrar el pop-up  
      // Mostrar el pop-up
      setIsError(true); 
     // delay(100);
      setShowPopup(true);
      setShowPopupEnd(false)
        
    setIsFinish(false); 
      // Guardar el código de advertencia para referencia futura si es necesario
      setWarningCode(3003);
    }
    if (error?.code === -5) {
      setIsFinish(false)
      setIsError(true);
      console.log("error 5")
      setShowPopupEnd(false)
    }
    if (error?.code === -10) {
      setIsFinish(true)
     // setIsError(false);
     // setShowPopupEnd(true)
     // setShowPopup(false);
     console.log("isFinish", isFinish)
      console.log("error 10")
      setShowPopup(!showPopup);
    }
    
  }, [error,  setErrorCount, errorCount , setShowError]);


  useEffect(() => {
    // Obtener el valor del idioma desde la URL
    const url = new URL(window.location.href);  
    // Obtén los parámetros de la URL
    const params = new URLSearchParams(url.search);
    const languageFromUrl = params.get('language_code');
console.log("idioma", languageFromUrl)
    if (languageFromUrl) {
      handleLanguageChange(languageFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
      
    } else if (
      (sessionState === SessionState.ACTIVE ||
        sessionState === SessionState.TERMINATED) &&
      errorMessage
    ) {
      setIsMeasurementEnabled(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }    
    if (isVideoReady()) {
      setShowPopup(true);
    }
    if (info?.message) {
      //setResetTimer(true);
      setResetTimer(false);
      setReiniciar(false);
    }
  }, [errorMessage, sessionState, isPageVisible, info]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  const mobile = useMemo(() => isMobile(), []);
  const desktop = useMemo(() => !isTablet() && !isMobile(), []);


  return (
    
    
    <>
   
   {!isMeasuring() && (
        <>
          <WarningAlert message={warningMessage} />

    {showPopup  && isVideoReady()  && !isFinish &&(
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(248 238 238 / 70%)', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
    >
      
     
      <div  style={{ border: 'solid',  backgroundColor: 'white', padding: '20px', borderRadius: '5px', margin: '20px', fontSize: 'larger'}}>
        {/* Contenido del pop-up */}
        {isError && (
  <div>
    <div style={{position: 'absolute', top: 0, left: 0, width: '23vw' }}>
      <img src={flecha} style={{height: '75%', width: '75%' }}/>
    </div>
    <p>{t('translation.poppup_error')}</p>
    <br></br>
    <p>{t('translation.poppup_error_count')}</p>
    <br></br>
  </div>
)}
      
        <p>{t('translation.poppup_firts')}</p>
         <div style={{textAlign: 'right'}}>
        <button style={{ fontSize: '20px',marginTop: '20px', fontFamily: 'MyriadPro-Regular', color: '#0091b3' , 
         backgroundColor: 'transparent', border:'unset'
        
         
        }} 
        onClick={(event) => togglePopup(event)}
        >
          <strong> {t('translation.empezar')} </strong> </button>   
        </div>
                
      </div>
    </div>
  )}

  {isFinish &&(

    <div 
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgb(248 238 238 / 70%)', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
    }}>
    <div style={{position: 'absolute', top: 0, left: 0, width: '23vw' }}>
      <img src={flecha} style={{height: '75%', width: '75%' }}/>
    </div>
    <div style={{ border: 'solid',  backgroundColor: 'white', padding: '20px', borderRadius: '5px', margin: '20px', fontSize: 'larger'}}>
          <p>{t('translation.poppup_end')}</p>
        </div>
  </div>
  )}
</>
      )}
  
      {/*<TopBar onSettingsClick={onSettingsClick} isMeasuring={isMeasuring()} />*/}
      <MonitorWrapper isSettingsOpen={isSettingsOpen}>
        <MeasurementContentWrapper isMobile={mobile}>
          <InfoBarWrapper>
            <InfoBar
              showTimer={isMeasurementEnabled && !isMediaTablet}
              isMeasuring={isMeasuring()}
              durationSeconds={processingTime}
              offlineMeasurements={offlineMeasurements}
              isError={isError}
              showError={showError}
              isFinish={isFinish}

            />
          </InfoBarWrapper>
          <VideoAndStatsWrapper isMobile={mobile}>
            <VideoWrapper>
              <Img src={Mask} isDesktop={desktop} />
              <Video
                ref={video}
                id="video"
                muted={true}
                playsInline={true}
                isMobile={isMobile()}
              />
            </VideoWrapper>
            {(isMeasuring()
              ? !errorMessage && !warningMessage
              : !errorMessage) &&
              isMeasurementEnabled && <Stats vitalSigns={vitalSigns} />}
            <ErrorAlert message={errorMessage} />
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {isMeasuring() && <InfoAlert message={info.message} />}
            {!isVideoReady() && licenseKey && <Loader />}
          </VideoAndStatsWrapper>
          <ButtomTimerWrapper>
            {isMeasurementEnabled &&  (
              <Timer started={isMeasuring()} durationSeconds={processingTime} isError={isError} isFinish={isFinish}  />
            )}
          
          </ButtomTimerWrapper>        
          
        </MeasurementContentWrapper>
      </MonitorWrapper>
    </>
  );
};

export default BinahMonitor;
