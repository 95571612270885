import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState} from 'react';
  import React from 'react';
import monitor, {
  AlertData,
  DeviceOrientation,
  EnabledVitalSigns,
  FaceSessionOptions,
  HealthMonitorCodes,
  HealthMonitorSession,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from '@binah/web-sdk';
import { InfoType, InfoData } from '../types';
import ApiService from '../components/SendVitalSigns';
import ClientAuthorization from 'components/ClientAuthorization';
import BinahMonitor from 'components/BinahMonitor';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



interface RouteParams {
  user_id: string; // Define la forma de tus parámetros de URL
  [key: string]: string; 
}

let termino: boolean = false;
const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  processingTime: number,
  licenseKey: string,
  productId: string,
  startMeasuring: boolean,
) => {
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [enabledVitalSigns, setEnabledVitalSigns] = useState<
    EnabledVitalSigns
  >();
  const [offlineMeasurements, setOfflineMeasurements] = useState<
    OfflineMeasurements
  >();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();

  const [error, setError] = useState<AlertData>({ code: -1 });
  const [warning, setWarning] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<InfoData>({ type: InfoType.NONE });
  const [envio, setEnvio] = useState<InfoData>({ type: InfoType.NONE });
  const isDismissing = useRef<boolean>(false);
  const { t } = useTranslation();
  const [localTimeElapsed, setLocalTimeElapsed] = useState(0);

  const setInfoWithDismiss = useCallback(
    (info: InfoData, seconds?: number) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current],
  );


  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      // Esta función se ejecuta cuando el componente se desmonta
      setIsMounted(false);
    };
  }, []);



 const verificData = (data) =>{  
    
    console.log("verificar data", Object.keys(data).length)
    if(Object.keys(data).length >= 19 ){     
      return  true
      }else{
        return  false
      }   
  };


  const SendData = async (data: any) => {
    let dataFalse = await verificData(data);
    console.log("verificar", dataFalse)
    if( dataFalse === false){
     
      
      console.log("dio error")
    }else{   
      
    
    console.log("dataError", data)

    window.postMessage('Success');
      // Obtén la URL actual
      const url = new URL(window.location.href);  
      // Obtén los parámetros de la URL
      const params = new URLSearchParams(url.search);
      // Obtén el valor del parámetro 'user_id'
      const user_id = params.get('user_id'); 
      const family_unit_user_id = params.get('delegado_id');  
      const diagnostic_test_id = params.get('diagnostic_test_id');  
      const server_url = params.get('server_url'); 
      console.log("server_url", server_url)

 setError({ code: -10 }); 
     // server_url=https://mgc-dev.doole.io
    
    const apiService = new ApiService();
    const apiClientAuthorization = new ClientAuthorization();
    let dataForm = apiService.formatArray(data, user_id, family_unit_user_id, diagnostic_test_id)
  //  if(dataForm.)
    console.log("dataFormat", dataForm)
   
      const urlFindBinahTest = 'api/binah/session/diagnosticTest';
      const clientAutho = 'oauth/token'
      let dataAutho;
    if(server_url === "https://mgc-dev.doole.io"){
      dataAutho = {
        grant_type: "client_credentials",
        client_id: "3",
        client_secret: "idW9Bt49LTtHJQszWlduHevR12mZurTNDUrGd0sF"
      }
    }
      else{
         dataAutho = {
          grant_type: "client_credentials",
          client_id: "4",
          client_secret: "lI98WB1kCEzXq5g7E2371jm16Gh1LRnCZCUb6D3h"
        }
      }

     
      
      //LLamada para obtener el token 
      apiClientAuthorization.post(clientAutho, dataAutho, server_url).subscribe(result => {
        if(result.access_token){
          let token = result.access_token;
          //llamada para guardar los datos en la historia clinica de la app
          apiService.post(urlFindBinahTest, dataForm, token, server_url)
          .then(data => {
            const dataDiagnostic = data;
              console.log('Datos obtenidos:', data);
              console.log('id del diagnosticTest:', data['diagnosticTest']['id']);
              window.postMessage('Success');
            })
            .catch(error => {
              console.error('Error en la solicitud:', error);
            });
        }
        console.log("tokendata", result)
        console.log("token", result.access_token)
      })
   }
  }

  const updateVitalSigns = useCallback((vitalSigns) => {  
  
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
   
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    console.log("resultTotals", vitalSignsResults.results)
    
   
    //estos son los resultados que se envian a la Api  despues que se termina el test
    //1min  
    
 //if( vitalSignsResults?.results?.wellnessLevel){
  if(Object.keys(vitalSignsResults.results).length < 19){
    let error:{
      code: 3003;
      domain?: 4000;
     }     
     setError({ code: -5 });     
      onError(error);
          }else{
           
            

              SendData(vitalSignsResults.results);
          }
    // SendData(vitalSignsResults.results);
 // }   
 

    console.log("vitalSignsResults", vitalSignsResults)
    updateVitalSigns(vitalSignsResults.results);
  }, []);

  const onError = (errorData: AlertData) => {
    setError(errorData);
  };

  const onWarning = (warningData: AlertData) => {
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING
    ) {
      setVitalSigns(null);
    }
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING
    ) {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION,
      });
    } else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback((state: SessionState) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements: OfflineMeasurements) => {
      setOfflineMeasurements(offlineMeasurements);
    },
    [],
  );

  const onActivation = useCallback((activationId: string) => {
    // the device has been activated with activationId
  }, []);

  const onFaceDetected = useCallback((isRect: boolean) => {
    if (!isRect) {
      setInfo({
        type: InfoType.INSTRUCTION,
        message: t('translation.Face_not_detected'),
      });
      setLocalTimeElapsed((prevTime) => {
        const newTimeElapsed = prevTime + 1;
        
        if (newTimeElapsed >= 10) {
          console.log("Enviar otro mensaje después de 10 segundos");
          setInfoWithDismiss({ type: InfoType.NONE });
          let error:{
            code: 3003;
            domain?: 4000;
           }
          // Resto del código para enviar otro mensaje o realizar acciones
          return 0; // Reinicia el temporizador
        }
  
        return newTimeElapsed;
      });
  
     
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, [setLocalTimeElapsed, t, setInfo, setInfoWithDismiss]);

 


  const onFinishTest = useCallback((temino: boolean) => {
    
    if (temino) {
      setInfo({
        type: InfoType.INSTRUCTION,
        message: 'Mission Cumplida',
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation,
          },
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e) {
        console.error('Error initializing HealthMonitor', e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey, productId]);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === SessionState.ACTIVE && session.terminate();

        const options: FaceSessionOptions = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onFaceDetected,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
          /*******************************************************************************
           * For accurate vital signs calculation the user's parameters should be provided.
           * The following is an example of how the parameters are passed to the SDK:
           *
           * subjectDemographic: {age: 35, weight: 75, gender: Gender.MALE}
           *
           * When measuring a new user then a new session must be created using the
           * new user's parameters
           * The parameters are used locally on the device only for the vital sign
           * calculation, and deleted when the session is terminated.
           *******************************************************************************/
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error('Error creating a session', e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  return {


    sessionState,
    vitalSigns: {
      heartRate: {
        value: vitalSigns?.heartRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledHeartRate,
      },
      breathingRate: {
        value: vitalSigns?.breathingRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledBreathingRate,
      },
      stress: {
        value: vitalSigns?.stressLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressLevel,
      },
      hrvSdnn: {
        value: vitalSigns?.sdnn?.value,
        isEnabled: enabledVitalSigns?.isEnabledSdnn,
      },
      spo2: {
        value: null, //TODO Spo2 is currently disabled by algo
        isEnabled: false, //enabledVitalSigns?.isEnabledSpo2,
      },
      bloodPressure: {
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
    },
    offlineMeasurements,
    error,
    warning,    
    info,
  };
};




export default useMonitor;
function onErrorCallback(error: { code: 3003; domain?: 4000; }) {
  throw new Error('Function not implemented.');
}

