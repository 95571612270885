import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import SettingsBars from './SettingsBars';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';
import StartButton from './StartButton';
import '../assets/i18n/i18n';

const Container = styled(Flex)<{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen && 'rgba(0, 0, 0, 0.5)'};
`;



  
const App = () => {
  const cameras = useCameras();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  useDisableZoom();

  const [browser, setBrowser] = useState('');

  const [showPopup, setShowPopup] = useState(true);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  

  const onSettingsClickedHandler = useCallback((event) => {
    const settingsBars = document.getElementById('settingsBars');
    const isSettingsButtonClicked = event.target.id === 'settingsButton';

    /*const isInsideSettingsClicked =
      settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

    if (!isInsideSettingsClicked) {
      setIsSettingsOpen(false);
    }*/
  }, []);

  useEffect(() => {
    document.addEventListener('click', onSettingsClickedHandler);
    return () => {
      document.removeEventListener('click', onSettingsClickedHandler);
    };
  }, []);

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const toggleSettingsClick = useCallback(() => {
    setIsSettingsOpen(!isSettingsOpen);
  }, [isSettingsOpen]);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setIsSettingsOpen(false);
  }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  useEffect(() => {
    const sUsrAg = navigator.userAgent;
    let sBrowser;

    if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome';
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari';
    }

    console.log("tipo de navegador", sBrowser)
    setBrowser(sBrowser);
  }, []);

  const isSupportedBrowser = browser === 'Google Chrome' || browser === 'Apple Safari';

  return (
    <Container isSettingsOpen={isSettingsOpen}>
      
      <BinahMonitor
        showMonitor={!(isMobile && isSettingsOpen)}
        cameraId={cameraId}
        onLicenseStatus={updateLicenseStatus}
        onSettingsClick={toggleSettingsClick}
        isSettingsOpen={isSettingsOpen}
        location
      />

    </Container>
  );
};

export default App;
