import axios from 'axios';
import { Observable } from 'rxjs';

class ApiService { 

//apiBaseUrl = 'https://mgc-dev.doole.io';

post(endpt, items, token, server_url) {
  const endpoint = `${server_url}/${endpt}`;

  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  return new Promise((resolve, reject) => {
    axios.post(endpoint, items, { headers })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}


formatArray(data, user_id, family_unit_user_id, diagnostic_test_id){
  
  let bloodPressure;
  const elements = Object.keys(data).map(ext_id => {
    if(ext_id == "bloodPressure"){
        bloodPressure= Object.keys(data[ext_id].value).map(key => {
        return {
          ext_id: key,
          value: data[ext_id].value[key]
        };
      });      
    }
    return {
      ext_id: ext_id,
      value: data[ext_id].value,
      confidenceLevel: data[ext_id].confidenceLevel
    };
  });

  console.log("formatArray", elements);
  elements.push(bloodPressure[0])
  elements.push(bloodPressure[1])
  return {
      user_id: user_id,
      family_unit_user_id: family_unit_user_id,
      diagnostic_test_id: diagnostic_test_id,
      elements: elements
  };
}

};



export default ApiService;
/*
export default {
  post: (url, data) => ApiService.post(url, data),
  // Otros métodos como get, put, delete según tus necesidades
};*/